<template>
  <svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_3468_35511)">
  <g filter="url(#filter0_d_3468_35511)">
  <path d="M15.9004 31C24.2004 31 30.9004 24.3 30.9004 16H0.900391C0.900391 24.3 7.60039 31 15.9004 31Z" fill="white"/>
  </g>
  <path d="M15.9004 1C7.60039 1 0.900391 7.7 0.900391 16H30.9004C30.9004 7.7 24.2004 1 15.9004 1Z" fill="#ED4C5C"/>
  </g>
  <defs>
  <filter id="filter0_d_3468_35511" x="-5.09961" y="11" width="42" height="27" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1"/>
  <feGaussianBlur stdDeviation="3"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3468_35511"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3468_35511" result="shape"/>
  </filter>
  <clipPath id="clip0_3468_35511">
  <rect width="32" height="32" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>

<script>
  export default {
    name: "IndonesiaFlag",
    props: {
      width: {
        type: Number,
        default: 32,
      },
      height: {
        type: Number,
        default: 32,
      },
    },
  };
  </script>